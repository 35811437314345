import request from "./request";
// 登录
export const Login = (body) => {
  return request({
    url: "/u/login",
    method: "POST",
    data: body,
  });
};
// 支付
export const wxPay = (data) => {
  return request({
    url: "/v/wx_add_pay_xin",
    method: "get",
    data,
  });
};
export const orderStatus = (data) => {
  return request({
    url: `v/order_state?order_sn=${data}`,
    method: "get",
  });
};
// 注册
export const Register = (body) => {
  return request({
    url: "/u/register",
    method: "POST",
    data: body,
  });
};

// 公司信息
export const UCompanyInfo = (data) => {
  return request({
    url: "/u/info",
    method: "get",
    data,
  });
};
export const articlexq = (data) => {
  return request({
    url: `/u/articlexq?id=${data}`,
    method: "get",
  });
};

export const logList = (data, page) => {
  return request({
    url: `/u/logs_list?tel=${data}&page=${page}`,
    method: "get",
    data,
  });
};
//账号密码 登录
export const passWordLogin = (body) => {
  return request({
    url: "/u/passlogin",
    method: "POST",
    data: body,
  });
};

// 获取验证码
export const sendSms = (body) => {
  return request({
    url: "/u/sendSms",
    method: "POST",
    data: body,
  });
};
// 获取扫码登录微信二维码
export const codeImg = (data) => {
  return request({
    url: "/pe/wxqrcode",
    method: "post",
    data,
  });
};
// 获取扫码登录建企查APP二维码
export const APPImg =(data)=>{
  return request({
    url:"/u/loginWebImage",
    method:"post",
    data
  })
}

// 检查微信登录状态
export const getLoginState = (data) => {
  return request({
    url: "/pe/checklogin",
    method: "post",
    data,
  });
};
// 检查建企查登录状态
export const getAppLoginState = (data) => {
  return request({
    url: "/u/isWebLogin",
    method: "post",
    data,
  });
};
// 领取半年会员
export const getHalfYearVIP = (data) => {
  return request({
    url: "u/user_vip_add",
    method: "post",
    data,
  });
};
//同步数据
export const userBangPerson = (data) => {
  return request({
    url: "p/user_bang_person",
    method: "post",
    data,
  });
};
// 绑定手机号
export const bindPhone = (params) => {
  return request({
    url: "/u/wx_login_phone",
    method: "GET",
    params,
  });
};

// 认证状态
export const getRenZhengState = () => {
  return request({
    url: "/u/certifi_info",
    method: "GET",
  });
};
//企业认证信息
export const getCompanyInfo = () => {
  return request({
    url: "/u/certifi_twoinfo",
    method: "GET",
  });
};
// 图片上传
export const upLoad = (body) => {
  return request({
    url: "/u/uploadimg_one",
    method: "POST",
    data: body,
  });
};
// 人员图片上传
export const upLoadPersonal = (body) => {
  return request({
    url: "/p/upload",
    method: "POST",
    data: body,
  });
};
// 提交企业认证信息
export const submitQiyeInfo = (body) => {
  return request({
    url: "/u/certifi_two",
    method: "POST",
    data: body,
  });
};
// 提交身份认证信息
export const submitInfo = (body) => {
  return request({
    url: "/u/certifi_one",
    method: "POST",
    data: body,
  });
};
// 获取省
export const getProvince = (params) => {
  return request({
    url: "/u/cityarr_data",
    method: "GET",
    params,
  });
};
// 获取企业数据
export const getqiyeList = (body) => {
  return request({
    url: "/e/enterp_list",
    method: "POST",
    data: body,
  });
};
// 匹配主题首页
export const getpipeiHome = (body) => {
  return request({
    url: "/i/index/matchTheme",
    method: "POST",
    data: body,
  });
};
// 企业详情基本数据
export const qiyeDetailjiben = (params) => {
  return request({
    url: "/e/enterp_details",
    method: "GET",
    params,
  });
};
// 会员列表套餐
export const getvipList = (params) => {
  return request({
    url: "/v/vip_list",
    method: "GET",
    params,
  });
};
// 会员支付
export const zhifu = (body) => {
  return request({
    url: "/v/create_order",
    method: "POST",
    data: body,
  });
};
// 行业列表
export const hangyeList = (params) => {
  return request({
    url: "/u/projec_type",
    method: "GET",
    params,
  });
};
// 服务
export const getfuwu = (params) => {
  return request({
    url: "/u/projec_two_type",
    method: "GET",
    params,
  });
};
// 行业列表1
export const hangyeList1 = (params) => {
  return request({
    url: "/u/projec_type_dingyue",
    method: "GET",
    params,
  });
};
// 服务分类1
export const getfuwufenlei = (params) => {
  return request({
    url: "/u/projec_two_type_dingyue",
    method: "GET",
    params,
  });
};
// 获取所有分类
export const getAllfuwufenlei = (params) => {
  return request({
    url: "/u/DIngyue/fuwuType",
    method: "GET",
    params,
  });
};
// 获取中标金额
export const getzhongbiaoMoney = (params) => {
  return request({
    url: "/u/dingyue_price",
    method: "GET",
    params,
  });
};
// 添加订阅
export const addSubscription = (body) => {
  return request({
    url: "/u/add_dingyue",
    method: "POST",
    data: body,
  });
};
// 编辑订阅列表
export const editDingyueList = (params) => {
  return request({
    url: "/u/edit_dingyue",
    method: "GET",
    params,
  });
};
// 编辑订阅
export const editDingyue = (body) => {
  return request({
    url: "/u/edit_do_dingyue",
    method: "POST",
    data: body,
  });
};
// 删除订阅
export const deldingyue = (params) => {
  return request({
    url: "/u/dingyue_del",
    method: "GET",
    params,
  });
};
// 获取订阅tab列表数据
export const getDingyuetabList = (params) => {
  return request({
    url: "/u/dingyue_list",
    method: "GET",
    params,
  });
};
// 获取订阅详情  获取订阅列表
export const getDingyueList = (params) => {
  return request({
    url: "/u/dingyue_data",
    method: "GET",
    params,
  });
};

// 订阅信息 企业展示
export const getDingyueDetail = (params) => {
  return request({
    url: "/e/ente_project_details",
    method: "GET",
    params,
  });
};

// 获取建企信息中标数据
export const getZhongbiaoNum = (params) => {
  return request({
    url: "/e/ent_type_count",
    method: "GET",
    params,
  });
};
// 获取中标明细数量
export const getZhongbiaomingxi = (params) => {
  return request({
    url: "/e/enter_ztb_list",
    method: "GET",
    params,
  });
};
// 获取中标图标统计
export const getZhongbiaotongji = (params) => {
  return request({
    url: "/e/enter_ztb_data",
    method: "GET",
    params,
  });
};
// 获取企业业绩数据
export const getyejiList = (params) => {
  return request({
    url: "/e/comp_projct",
    method: "GET",
    params,
  });
};
// 获取企业业绩分类数据
export const getyejiClassList = (params) => {
  return request({
    url: "/u/yunqi_type",
    method: "GET",
    params,
  });
};
// 获取业绩详情基本信息
export const getyejiInfo = (params) => {
  return request({
    url: "/e/addon_detail",
    method: "GET",
    params,
  });
};
// 企业业绩详情
export const getyejixiangqing = (params) => {
  return request({
    url: "/e/user_perfor_detail",
    method: "GET",
    params,
  });
};
// 获取业绩详情单体信息详情
export const getyejidantiDetail = (params) => {
  return request({
    url: "/e/addon_dt",
    method: "GET",
    params,
  });
};
// 获取业绩详情合同登记信息
export const geyejitHetong = (params) => {
  return request({
    url: "/e/addon_ht",
    method: "GET",
    params,
  });
};
// 获取业绩详情合同登记详情信息
export const getyejitHetongDetail = (params) => {
  return request({
    url: "/e/addon_ht_detail",
    method: "GET",
    params,
  });
};
// 获取业绩详情施工审查
export const getyejishigongshencha = (params) => {
  return request({
    url: "/e/addon_sgtsc",
    method: "GET",
    params,
  });
};
// 获取业绩详情施工审查
export const getyejishigongDetail = (params) => {
  return request({
    url: "/e/addon_sgtsc_detail",
    method: "GET",
    params,
  });
};

// 获取业绩详情专业技术人员名单
export const getyejijishurenyuan = (params) => {
  return request({
    url: "/e/addon_jishu_dat",
    method: "GET",
    params,
  });
};

// 获取业绩详情施工许可信息
export const getyejishigongxuke = (params) => {
  return request({
    url: "/e/addon_sgtxk",
    method: "GET",
    params,
  });
};

// 获取业绩详情竣工信息
export const getyejijungong = (params) => {
  return request({
    url: "/e/addon_jgxinx",
    method: "GET",
    params,
  });
};

// 建筑资质
export const getqiyezizhi = (body) => {
  return request({
    url: "/e/enterp_zizhi",
    method: "POST",
    data: body,
  });
};
// 资质资格详情
export const getzizhiEcharts = (params) => {
  return request({
    url: "/e/zzlb_statistics",
    method: "GET",
    params,
  });
};
// 建筑资质详情的下拉列表
export const getzizhiState = (params) => {
  return request({
    url: "/e/enterp_search",
    method: "GET",
    params,
  });
};

// 获取注册人员列表
export const getzhucerenyuan = (params) => {
  return request({
    url: "/e/user_list",
    method: "GET",
    params,
  });
};
// 获取注册人员注册类别
export const getzhucerenyuanleibie = (params) => {
  return request({
    url: "/e/user_type",
    method: "GET",
    params,
  });
};
// 获取注册人员详情信息
export const getzhucerenyuanDetail = (params) => {
  return request({
    url: "/e/user_detail",
    method: "GET",
    params,
  });
};
// 获取注册人员详情业绩信息
export const getzhucerenyuanDetailyeji = (params) => {
  return request({
    url: "/e/user_qyj",
    method: "GET",
    params,
  });
};
// 获取注册人员个人类别
export const getzhucerenyuangerenleibie = (params) => {
  return request({
    url: "/e/user_lb",
    method: "GET",
    params,
  });
};
// 获取注册人员信息变更记录
export const getzhucerenyuanDetailbiangengjilu = (params) => {
  return request({
    url: "/e/get_company_people",
    method: "GET",
    params,
  });
};
// 获取企业招聘信息
export const getzhaopin = (params) => {
  return request({
    url: "/e/recruit_list",
    method: "GET",
    params,
  });
};
// 获取企业招聘数据统计
export const getzhaopinTongji = (params) => {
  return request({
    url: "/e/recruit_data",
    method: "GET",
    params,
  });
};
// 获取企业基本信息
export const getbasicInfo = (params) => {
  return request({
    url: "/e/enterp_jiben",
    method: "GET",
    params,
  });
};
// 企业股东信息 注册人员信息 变更记录
export const getbasicInfoList = (params) => {
  return request({
    url: "/e/get_company_people",
    method: "GET",
    params,
  });
};

// 企业年报列表
export const getbasicInfonianbao = (params) => {
  return request({
    url: "/e/enterp_qynb_list",
    method: "GET",
    params,
  });
};
// 企业年报详情信息
export const getbasicInfonianbaoDetail = (params) => {
  return request({
    url: "/e/enterp_qynb_detail",
    method: "GET",
    params,
  });
};
// 企业风险 -列表信息
export const getfengxianList = (params) => {
  return request({
    url: "/e/get_company_risk",
    method: "GET",
    params,
  });
};
// 企业风险 - 4 大案件
export const getfenganjian = (params) => {
  return request({
    url: "/e/get_company_anjian",
    method: "GET",
    params,
  });
};
// 企业风险 -分类信息
export const getfengxianClass = (params) => {
  return request({
    url: "/e/get_courtlevel",
    method: "GET",
    params,
  });
};
// 企业风险信息 -企业案件
export const getfengxianqiyeanjian = (params) => {
  return request({
    url: "/e/get_company_anjian",
    method: "GET",
    params,
  });
};
// 企业风险信息 -分类信息
export const getfengxianfenleixinxi = (params) => {
  return request({
    url: "/e/get_courtlevel",
    method: "GET",
    params,
  });
};
// 企业风险--司法协助信息详情
export const getfengxiansifaDetail = (params) => {
  return request({
    url: "/e/get_company_sfxz",
    method: "GET",
    params,
  });
};
// 企业风险信息 -抽查企业信息
export const getfengxianchoucha = (params) => {
  return request({
    url: "/e/get_company_choucha",
    method: "GET",
    params,
  });
};

// 知识产权信息------商标信息
export const getzhishichanquanInfo = (params) => {
  return request({
    url: "/e/get_company_property",
    method: "GET",
    params,
  });
};
// 知识产权信息------商标信息
export const getzscqTongji = (params) => {
  return request({
    url: "/e/get_company_zhuanli_data",
    method: "GET",
    params,
  });
};
// 知识产权信息-------软著
export const getzscqruanzhu = (params) => {
  return request({
    url: "/e/get_company_zuopin",
    method: "GET",
    params,
  });
};
// 知识产权信息-------专利信息统计
export const getzscqzhuanlitongji = (params) => {
  return request({
    url: "/e/get_company_data",
    method: "GET",
    params,
  });
};
// 知识产权信息-------专利信息
export const getzscqzhuanlixinxi = (params) => {
  return request({
    url: "/e/get_company_zhuanli",
    method: "GET",
    params,
  });
};
// 经营信息------企业资质
export const getjingyinzizhi = (params) => {
  return request({
    url: "/e/qualifications",
    method: "GET",
    params,
  });
};
// 经营信息------- 网站 公众号
export const getjingyinweb = (params) => {
  return request({
    url: "/e/mortgage",
    method: "GET",
    params,
  });
};
// 经营信息-------抵押
export const getjingyindiya = (params) => {
  return request({
    url: "/e/dcdy_list",
    method: "GET",
    params,
  });
};
// 经营信息-------抵押详情
export const getjingyindiyaDetail = (params) => {
  return request({
    url: "/e/dcdy_delta",
    method: "GET",
    params,
  });
};
// 经营信息------招聘信息
export const getjingzhaopin = (params) => {
  return request({
    url: "/e/recruit",
    method: "GET",
    params,
  });
};
// 历史信息------工程项目
export const getHistoryInfo = (params) => {
  return request({
    url: "/e/engineering",
    method: "GET",
    params,
  });
};
// 历史信息------企业建造师
export const getHistoryjianzaoshi = (params) => {
  return request({
    url: "/e/construct",
    method: "GET",
    params,
  });
};

// 历史信息------招投标
export const getHistoryzhaobiao = (params) => {
  return request({
    url: "/e/recruit_ztb",
    method: "GET",
    params,
  });
};
// 历史信息------招投标详情
export const getHistoryzhaobiaoDetail = (params) => {
  return request({
    url: "/e/recruit_detail",
    method: "GET",
    params,
  });
};
// 历史信息------安全许可
export const getHistoryanquanxuke = (params) => {
  return request({
    url: "/e/secure",
    method: "GET",
    params,
  });
};
// 历史信息------许可证信息
export const getHistoryxukezheng = (params) => {
  return request({
    url: "/e/secure_xukezheng",
    method: "GET",
    params,
  });
};
// ------获取企业是否解锁
export const getqiyeShowJiesuo = (params) => {
  return request({
    url: "/c/enterprise_unlock",
    method: "GET",
    params,
  });
};
// ------分配他人列表
export const getxiansuo = (params) => {
  return request({
    url: "/c/fetchData",
    method: "GET",
    params,
  });
};
// ------线索添加--解锁
export const xiansuoAdd = (data) => {
  return request({
    url: "/c/add",
    method: "POST",
    data,
  });
};
// ------获取消息列表
export const getMessageList = (params) => {
  return request({
    url: "/u/message_list",
    method: "GET",
    params,
  });
};
// 未读消息条数
export const messageCount = (params) => {
  return request({
    url: "/u/message_count",
    method: "GET",
    params,
  });
};
// ------设置信息已读/删除
export const setMessage = (data) => {
  return request({
    url: "/u/message_edit",
    method: "POST",
    data,
  });
};
// 跟踪查询
export const getCompanyState = (params) => {
  return request({
    url: "/c/followup/get",
    method: "delete",
    params,
  });
};
// ------客户添加
export const AddCrm = (data) => {
  return request({
    url: "/c/Client/add",
    method: "POST",
    data,
  });
};
// 获取客户列表
export const getContactList = (data) => {
  return request({
    url: "/c/Client/contact_list",
    method: "POST",
    data,
  });
};
// 客户转入公海
export const gonghai = (data) => {
  return request({
    url: "/c/Client/high_seas",
    method: "POST",
    data,
  });
};
// 获取个人信息
export const getUserInfo = (params) => {
  return request({
    url: "/u/user_detail",
    method: "GET",
    params,
  });
};
// 购买线索下单
export const paymentOrder = (data) => {
  return request({
    url: "/v/clue_order",
    method: "POST",
    data,
  });
};
// 个人中心线索订单详情
export const getxiansuoOrder = (params) => {
  return request({
    url: "v/clue_details",
    method: "GET",
    params,
  });
};
// 编辑个人信息
export const editUserInfo = (data) => {
  return request({
    url: "/u/user_edit",
    method: "POST",
    data,
  });
};
// 获取第三方城市
export const getCity = (data) => {
  return request({
    url: "/u/pccinfo",
    method: "get",
    data,
  });
};
// 提交第三方账号密码信息
export const addThird = (data) => {
  return request({
    url: "/u/unitinfoadd",
    method: "POST",
    data,
  });
};
// 获取第三方账号密码信息
export const getThird = (data) => {
  return request({
    url: "/u/unitinfo_info",
    method: "get",
    data,
  });
};

// 忘记密码
export const editPassWord = (data) => {
  return request({
    url: "/u/pwd_upd",
    method: "POST",
    data,
  });
};
//问题标题列表
export const getProblem = (data) => {
  return request({
    url: "/r/problem",
    method: "get",
    data,
  });
};
// 添加用户提问信息
export const addQuestion = (data) => {
  return request({
    url: "/r/problem_add",
    method: "post",
    data,
  });
};
//问题回复内容
export const getProblemDetial = (data) => {
  return request({
    url: "/r/detial",
    method: "post",
    data,
  });
};
// 切换地址
export const editCity = (data) => {
  return request({
    url: "/e/city_update",
    method: "POST",
    data,
  });
};
// 获取销售简报
export const getXiaoshoujianbao = (params) => {
  return request({
    url: "/e/user_sale",
    method: "GET",
    params,
  });
};
// 获取解锁列表
export const getjiesuoList = (data) => {
  return request({
    url: "/c/sale_list",
    method: "POST",
    data,
  });
};
// 获取解锁列表
export const unlockListAddCrm = (data) => {
  return request({
    url: "/c/crm_add",
    method: "POST",
    data,
  });
};
// 获取公海列表的数据
export const getgonghaiList = (data) => {
  return request({
    url: "/c/Client/high_seas_list",
    method: "POST",
    data,
  });
};
// 获取订单管理列表
export const getOrderList = (params) => {
  return request({
    url: "/v/order_list",
    method: "GET",
    params,
  });
};
// 获取订单详情
export const getOrderInfo = (data) => {
  return request({
    url: `/v/order_info?oid=${data}`,
    method: "GET",
  });
};
//用户发票抬头新增修改
export const receiptChange = (data) => {
  return request({
    url: "/u/create_invoic",
    method: "POST",
    data,
  });
};
//用户发票抬头信息
export const receiptInfo = (data) => {
  return request({
    url: "/u/invoic_detail",
    method: "POST",
    data,
  });
};
//申请开具订单发票提交信息
export const receipt = (data) => {
  return request({
    url: "/u/invoicing",
    method: "POST",
    data,
  });
};
//查询订单发票信息
export const orderReceiptInfo = (data) => {
  return request({
    url: "/u/invoic_order",
    method: "POST",
    data,
  });
};
// 公海领取客户
export const lingqukehu = (data) => {
  return request({
    url: "/c/Client/receive_save",
    method: "POST",
    data,
  });
};

// 修改手机号发送验证码
export const getRuleCode = (data) => {
  return request({
    url: "/u/main_edit_phone_code",
    method: "POST",
    data,
  });
};
// 点击修改手机号
export const editPhoneNumber = (data) => {
  return request({
    url: "/u/execute_edit_phone",
    method: "POST",
    data,
  });
};
// 获取认证企业 logo和名称
export const getCompanyLogo = (params) => {
  return request({
    url: "/u/get_logo_name",
    method: "GET",
    params,
  });
};
// 获取账号列表
export const getAccountList = (params) => {
  return request({
    url: "/u/account_list",
    method: "GET",
    params,
  });
};
// 编辑子账号
export const editUser = (data) => {
  return request({
    url: "/u/account_upd",
    method: "POST",
    data,
  });
};
// 编辑子账号
export const deleteUser = (data) => {
  return request({
    url: `/u/account_del?mid=${data}`,
    method: "GET",
  });
};
// 新建子账号
export const addUser = (data) => {
  return request({
    url: "/u/account_add",
    method: "POST",
    data,
  });
};
// 获取话术列表
export const getVerbalTrick = (params) => {
  return request({
    url: "/u/get_script_list",
    method: "GET",
    params,
  });
};
// 修改/添加话术信息
export const editHuashu = (data) => {
  return request({
    url: "/u/add_script",
    method: "POST",
    data,
  });
};
// 删除话术
export const deleteHuashu = (data) => {
  return request({
    url: "/u/del_script",
    method: "delete",
    data,
  });
};
// 查看话术
export const lookHuashu = (data) => {
  return request({
    url: "/u/edit_script",
    method: "POST",
    data,
  });
};
//获取地区
export const diqu = (data) => {
  return request({
    url: "/u/cityarr_data",
    method: "GET",
    data,
  });
};
// 申报材料
// 材料类型列表
export const fileTypeList = (data) => {
  return request({
    url: "/m/type",
    method: "post",
    data,
  });
};
// 材料列表
export const fileList = (data) => {
  return request({
    url: "/m/list",
    method: "post",
    data,
  });
};
// 材料文件上传
export const uploadFile = (data) => {
  return request({
    url: "/u/uploadinfo_one",
    method: "post",
    data,
  });
};
//资质库
// 添加资质
export const addQualifications = (data) => {
  return request({
    url: "/q/add",
    method: "POST",
    data,
  });
};
// 获取企业业绩分类
export const getEnterprise = (params) => {
  return request({
    url: "/u/yunqi_type",
    method: "GET",
    params,
  });
};
//资质列表
export const getQualification = (data) => {
  return request({
    url: "/q/list",
    method: "POST",
    data,
  });
};
// 资质详情
export const qualificationsDetail = (data) => {
  return request({
    url: "/q/getDetails",
    method: "POST",
    data,
  });
};
// 安许详情
export const getAxDetail = (data) => {
  return request({
    url: "/q/axDetails",
    method: "POST",
    data,
  });
};
//删除资质
export const deleteQualification = (data) => {
  return request({
    url: "/q/del",
    method: "POST",
    data,
  });
};
// 人员接口

// 获取人员列表
export const getPersonList = (params) => {
  return request({
    url: "/p/list",
    method: "POST",
    params,
  });
};
export const getBindPersonList = (params) => {
  return request({
    url: "/p/qplist",
    method: "POST",
    params,
  });
};
// 关联已有人员
export const bindInfo = (data) => {
  return request({
    url: "/p/bind_info",
    method: "post",
    data,
  });
};
// 人员类型
export const getPersonType = (params) => {
  return request({
    url: "/p/pesontype",
    method: "post",
    params,
  });
};
// 人员分类证书名称
export const getPersonTypes = (params) => {
  return request({
    url: "/p/pesontypes",
    method: "POST",
    params,
  });
};
// 人员监控、资质修改
export const personMonitor = (params) => {
  return request({
    url: "/q/up",
    method: "POST",
    params,
  });
};
// 添加人员
export const addPerson = (data) => {
  return request({
    url: "/p/add",
    method: "POST",
    data,
  });
};
//修改人员
export const editPerson = (data) => {
  return request({
    url: "/p/up",
    method: "POST",
    data,
  });
};
// 人员详情
export const personDetail = (data) => {
  return request({
    url: "/p/getDetail",
    method: "POST",
    data,
  });
};
// 删除人员
export const deletePerson = (data) => {
  return request({
    url: "/p/del",
    method: "POST",
    data,
  });
};

// 动态

// 动态通知列表
export const getTrendList = (data) => {
  return request({
    url: "/n/list",
    method: "POST",
    data,
  });
};
// 动态通知详情
export const getTrendDetial = (data) => {
  return request({
    url: "/n/detial",
    method: "POST",
    data,
  });
};

// 监控
export const monitor = (data) => {
  return request({
    url: "/v/jk",
    method: "POST",
    data,
  });
};
// 国家和各省的住建部访问连接
export const uniturl = (data) => {
  return request({
    url: `/p/uniturl?url_id=${data}`,
    method: "get",
    data,
  });
};
